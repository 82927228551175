@import '../../theme.scss';
#regisztracio {
	h2.title {
		color: $iok-light;
	}
	.text {
		color: $iok-light;
	}
	h3 {
		text-align: center;
		color: $iok-light;
	}
	p.subtitle{
		margin-bottom: 0;
	}
	.reg-form {
		max-width: 700px;
		margin: auto;
		input, textarea {
			margin-bottom: 18px;
		}
	}
	.form-label, .form-check-label {
		color: $iok-light;
		font-size: 0.90rem;
		font-weight: 600;		
	}
	p {
		color: $iok-light;
		font-size: 0.90rem;
	}
	.form-check-input:checked {
		background-color: $primary;
		border-color: adjust-color($color: $primary, $lightness: -15%);
	}
	.form-check-input:focus {
		box-shadow: none;
	}
	.form-check {
	}
	.form-control, .form-select {
		&:focus {
			box-shadow: 0 0 0 0.1rem $primary;
			border-color: $primary;
		}
	}
	input, select, select option {
		font-family: sans-serif;
	}
	.alert {
		font-size: 0.9rem;
		line-height: 0.9;
		font-weight: 600;
		padding: 1.05rem 1rem 0.9rem 1rem;
	}
	button {
		position: relative;
	}
}

.modal {
	.modal-title.h4 {
		font-size: 1.2rem;
		font-weight: 600;
	}
	.modal-body {
		font-size: 0.92rem;
	}
}