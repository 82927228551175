@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import './theme.scss';
* {
	font-family: 'Spartan', sans-serif;
	z-index: 10;
}

body, html {
	max-width: 100vw;
	overflow-x: hidden;
}

body {
	background-color: $bg-primary;
}

hr {
	background-color: $primary;
	height: 1px !important;
	opacity: 1;
}

a.link {
	color: $primary;
	transition: 0.1s ease-out;
	text-decoration: none;
	&:hover {
		color: adjust-color($color: $primary, $lightness: -15%);
		text-decoration: underline;
	}
}