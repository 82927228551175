@import '../../theme.scss';

#program {
	.shcedule-item {
		margin: 15px 0;
		transition: all 0.2s ease-out;
		padding: 10px;
		height: auto;
		border-radius: 40px;
		@include mobile {
			height: auto;
		}
		cursor: pointer;
		.images {
			width: 70px;
			display: inline-block;
			align-self: center;
			vertical-align: middle;
			transition: all 0.2s ease-out;
			.image {
				width: 100%;
				transition: all 0.2s ease-out;
				background-size: cover;
				background-position: center;
				border-radius: 100%;
				aspect-ratio: 1;
				align-self: center;
				//border: 1px solid $lightgrey;
			}
		}

		.content {
			transition: all 0.2s ease-out;
			display: inline-block;
			padding-left: 20px;
			width: calc(100% - 200px);
			@include mobile {
				width: calc(100% - 70px);
			}
			vertical-align: top;
		}
		
		.time {
			transition: all 0.2s ease-out;
			font-size: 0.75rem;
			font-weight: 600;
			color: rgba(0, 0, 0, 0.5);
			padding-top: 5px;
			svg {
				transition: all 0.2s ease-out;
				fill: rgba(0, 0, 0, 0.5);
				height: 15px;
				transform: translateY(-2.5px);
				margin-right: 8px;
			}
			@include mobile {
				font-size: 0.7rem;
			}
		}

		.title {
			transition: all 0.2s ease-out;
			font-size: 1.1rem;
			font-weight: 700;
			padding: 0px 0 1px 0;
			@include mobile {
				font-size: 0.85rem;
			}
		}

		.presenter {
			transition: all 0.2s ease-out;
			font-size: 0.75rem;
			font-weight: 500;
			color: rgba(0, 0, 0, 0.5);
		}

		.description {
			transition: all 0.2s ease-out;
			overflow: hidden;
			font-size: 0.9rem;
			max-height: 0;
			color: rgba(0, 0, 0, 0.6);

			@include mobile {
				width: calc(100% + 70px);
				transform: translateX(-80px);
				font-size: 0.8rem;
			}
		}

		&:hover {
			background-color: rgba(255, 255, 255, 0.6);
		}
		
		&.open {
			@include desktop {
				padding: 20px;
				border-radius: 20px;
				.images {
					width: 200px;
					.image {
						border-bottom-left-radius: 0;
						width: 200px;
					}
				}

				&.double-presenter {
					.images {
						width: 160px;
						.image {
							border-bottom-left-radius: 0;
							width: 150px;
						}
					}
				}

				.content {
					width: calc(100% - 200px);
				}

				.time {
					font-size: 1rem;
					svg {
						height: 30px;
					}
				}

				.title {
					font-size: 1.6rem;
				}

				.presenter {
					font-size: 1rem;
					font-weight: 600;
				}

				.description {
					max-height: 2000px;
					padding: 15px 0 0 0;
				}
			}
			@include mobile {
				background-color: rgba(255, 255, 255, 0.8);
				padding-bottom: 15px;
				.description {
					max-height: 2000px;
				}
				.presenter {
					margin-bottom: 20px;
				}
			}
		}
	}
	.separator {
		text-align: center;
		position: relative;
		padding: 40px 0;
		.line {
			height: 2px;
			background-color: $primary;
			
		}
		.separator-text {
			color: $iok-light;
			border: 2px solid $primary;
			height: 45px;
			border-radius: 22.5px;
			background-color: $bg-primary;
			padding: 5px 30px;
			font-size: 0.9rem;
			line-height: 32px;
			font-weight: 600;
			display: inline-block;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			svg {
				fill: $iok-light;
				height: 15px;
				margin-right: 10px;
				transform: translateY(-1px);
				width: 15px;
			}
		}
	}
	.tabs-container {
		width: 95%;
		padding: 40px 40px;
		border: 5px solid $primary;
		border-radius: 15px;
		margin: auto;
		background-color: rgba(255, 255, 255, 0.2);
		position: relative;
		overflow: hidden;
		margin-top: 30px;
		@include mobile {
			padding: 40px 15px 10px 15px;
		}
		h3 {
			font-size: 2rem;
			font-weight: 700;
			margin-bottom: 20px;
			text-align: center;
		}
		.tabs {
			width: 100%;
			max-width: 800px;
			margin: 40px auto 30px auto;
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			border: 3px solid $primary;
			height: 55px;
			border-radius: 100px;
			position: relative;
			@include mobile {
				width: 100%;
			}
			.tab-button {
				border-radius: 100px;
				height: 50px;
				color: $grey;
				overflow: hidden;
				cursor: pointer;
				&.selected {
					color: $light;
				}
				text-align: center;
				font-size: 1rem;
				font-weight: 600;
				padding: 5px 20px;
				line-height: 45px;
				transition: all 0.1s ease-out;
				&:not(.selected):hover {
					background-color: rgba(255, 255, 255, 0.6);
					color: adjust-color($color: $primary, $lightness: -10%)
				}
			}
			.selector {
				position: absolute;
				background-color: $primary;
				top: 0;
				width: 0;
				height: 52px;
				transform: translate(-1px, -1px);
				border-radius: 100px;
				transition: all 0.2s ease-in-out;
			}
		}
		.sessions-container {
			position: relative;
			overflow: hidden;
			transition: all 0.2s ease-out;
		}
		.sessions {
			position: absolute;
			transition: all 0.3s ease-in-out;
			width: 100%;
			&.selected {
				left: 0;
			}
			&.right {
				left: 100%;
			}
			&.left {
				left: -100%;
			}
			h4 {
				font-weight: 700;
				color: adjust-color($primary, $lightness: -7%);
				text-align: center;
				font-size: 1.5rem;
			}
		}
	}
}